import React from 'react';
import { graphql } from 'gatsby';
import { formatManufacturer } from '../formatters/manufacturer';
import Body from '../components/Body';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ContentWithSidebar from '../components/ContentWithSidebar';
import styles from './ManufacturerTemplate.module.scss';

const ManufacturerTemplate = ({ data }) => {
  const { title, body, metaDescription, metaTitle } = formatManufacturer(data);

  return (
    <Layout>
      <Meta title={metaTitle || title} description={metaDescription} />
      <Section>
        <ContentWithSidebar>
          <div className={styles.heading}>
            <h1 className={styles.title}>{title}</h1>
          </div>
          <Body content={body} />
        </ContentWithSidebar>
      </Section>
    </Layout>
  );
};

export default ManufacturerTemplate;

export const manufacturerFragment = graphql`
  fragment ManufacturerFragment on MarkdownRemark {
    id
    fields {
      path
      slug
    }
    frontmatter {
      title
      image
      metaDescription
      metaTitle
    }
    html
  }
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...ManufacturerFragment
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "manufacturer" }, hidden: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 6
    ) {
      nodes {
        ...ManufacturerFragment
      }
    }
  }
`;
